import React from 'react';

const About = () => {
    return (
        <section id="maps">
            <div className="container">
                <div className="spacer-10"></div>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6 text-center">
                        <h1>
                            Nosotros
                        </h1>
                        <p>
                            ¡The best team!
                        </p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12 col-lg-6">
                        <img src="/static/CivilPaulayInaki-5.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                        <img src="/static/CivilPaulayInaki-45.jpg" alt="" className="img-fluid" />
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col-6 col-lg-3">
                        <img src="/static/CivilPaulayInaki-33.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="col-6 col-lg-3">
                        <img src="/static/CivilPaulayInaki-34.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="col-6 col-lg-3 mt-4 mt-lg-0">
                        <img src="/static/CivilPaulayInaki-50.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="col-6 col-lg-3 mt-4 mt-lg-0">
                        <img src="/static/CivilPaulayInaki-62.jpg" alt="" className="img-fluid" />
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col-12 col-lg-4">
                        <img src="/static/CivilPaulayInaki-65.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="col-12 col-lg-4 mt-4 mt-lg-0">
                        <img src="/static/CivilPaulayInaki-93.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="col-12 col-lg-4 mt-4 mt-lg-0">
                        <img src="/static/CivilPaulayInaki-98.jpg" alt="" className="img-fluid" />
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col-6">
                        <img src="/static/CivilPaulayInaki-135.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="col-6">
                        <img src="/static/CivilPaulayInaki-148.jpg" alt="" className="img-fluid" />
                    </div>
                </div>
                <div className="spacer-15"></div>
            </div>
        </section>
    )
}

export default About;