import React from 'react';

const Playlist = () => {
    return (
        <section id="maps" className='bg-black text-white'>
            <div className="container">
                <div className="row">
                    <div className="spacer-10"></div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-6 text-center">
                            <h1>
                                ¡AYÚDANOS A PRENDER LA FIESTA!
                            </h1>
                            <p className='py-3'>
                                Siéntete libre de añadir a nuestra playlist las canciones que no pueden faltar para tener la mejor fiesta.
                            </p>
                            <a href="https://open.spotify.com/embed/playlist/61Mf9HyIC66xknVOJXnkz8" className='btn btn-black' target="_blank" rel="noopener noreferrer">AGREGAR CANCIONES</a>
                        </div>
                    </div>
                    <div className="col d-flex justify-content-center mt-4">
                        <iframe title="playlist" src="https://open.spotify.com/embed/playlist/61Mf9HyIC66xknVOJXnkz8?utm_source=generator&theme=0" frameBorder="0" className='playlist-iframe'></iframe>
                    </div>
                    <div className="spacer-10"></div>
                </div>
            </div>
        </section>
    )
}

export default Playlist;