import React, { useEffect, useState } from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';

const Banner = () => {
    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => setOffsetY(window.scrollY);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <section id="main-banner">
            <img src="/static/CivilPaulayInaki-145B.jpg" className='mainBannerImg' style={{ transform: `translateY(${offsetY * 0.5}px)` }} />
            <div className="fadeWrapper">
                <div className="position-absolute bottom-50 start-50 translate-middle text-white text-center bannerContainer mb-5">
                    <div className="container p-5">
                        <div className="row">
                            <div className="col">
                                <h1 className="display-1">Paula e Iñaki</h1>
                                <h2 className="fs-2">06 Agosto 2022</h2>
                                <h3 className='fw-300'>Santiago, Chile</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Banner;