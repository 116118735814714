import React from 'react';

const Giftlist = () => {
    return (
        <section id="gift-list" className='text-white'>
            <div className="spacer-15"></div>
            <div className="row justify-content-center g-0">
                <div className="col-12 col-lg-6 bg-black py-5">
                    <div className="container px-3">
                        <div className="row justify-content-center mt-4">
                            <div className="col-4 p-0 text-center">
                                <img src="/static/novios-paris-logo.png" alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col text-center">
                                <h1>LISTA DE REGALOS</h1>
                                <p className='my-4'>
                                    Si quieres hacernos un regalo, agradeceríamos mucho que lo hicieras a través de la plataforma de Novios Paris, utilizando el código
                                    <br />
                                    <br />
                                    <span className='title-gold fw-bolder h2'>18277247</span>
                                    <br />
                                    <br />
                                    También puedes acceder directamente a nuestra lista de regalos en el siguiente botón:
                                </p>
                                <a href="https://club.noviosparis.cl/home/couple-catalog/18277247" className="btn btn-gold text-black fw-bold" target="_blank" rel="noopener noreferrer">IR A LA LISTA</a>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col text-center">
                                <small>
                                    Todos tus regalos aportan para nuestra luna de miel.
                                </small>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col text-center">
                                <h4>¡Muchas gracias!</h4>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-4">
                            <div className="col-3 col-lg-2 p-0 text-center">
                                <img src="/static/rococo-separator-bottom.png" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer-15"></div>
        </section>
    )
}

export default Giftlist;