import React, { useEffect, useState } from 'react';

const Counter = () => {
    const calculateTimeLeft = () => {
        let year = new Date().getFullYear();
        let difference = +new Date(`08/06/${year} 16:30:00`) - +new Date();

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                Días: Math.floor(difference / (1000 * 60 * 60 * 24)),
                Horas: Math.floor((difference / (1000 * 60 * 60)) % 24),
                Minutos: Math.floor((difference / 1000 / 60) % 60),
                Segundos: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    }
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });
    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        timerComponents.push(
            <div className="col text-center" key={interval}>
                <h1>
                    {!!timeLeft[interval] ? (timeLeft[interval]) : "0"}
                </h1>
                <h5>
                    {interval}
                </h5>
            </div>
        );
    });
    return (
        <>
            <section id='acompañanos' className='bg-black text-white'>
                <div className="spacer-10"></div>
                <div className="container pt-5">
                    <div className="row justify-content-around">
                        <div className="col-12 col-lg-6 mt-5 order-2 order-lg-1">
                            <img src="/static/CivilPaulayInaki-16.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-12 col-lg-5 order-1 order-lg-2 d-flex flex-column justify-content-end">
                            <h1 className='fs-2 fw-600'>¡NOS CASAMOS!</h1>
                            <h2 className='my-3'>TE INVITAMOS A ACOMPAÑARNOS EN ESTE GRAN DÍA</h2>
                            <p className='text-justify'>
                                Después de 10 años juntos,
                                estamos felices de invitarte a compartir y celebrar junto a nosotros este momento tan especial.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="counter" className="bg-black text-white">
                <div className="spacer-30"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-6 text-center">
                            <img src="/static/dark-deco-bg-middle.png" className='img-fluid t-180' alt="" />
                            <div className='py-5'>
                                <div className="row">
                                    {timerComponents.length ? timerComponents : <h1>¡Llegó el día!</h1>}
                                </div>
                                <h3 className='fw-300 mt-3'>
                                    06 Agosto 2022, 16:30 hrs.
                                </h3>
                            </div>
                            <img src="/static/dark-deco-bg-middle.png" className='img-fluid' alt="" />
                        </div>
                    </div>
                </div>
                <div className="spacer-25"></div>
            </section>
        </>
    )
}

export default Counter;