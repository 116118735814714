import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

// components vvv
import Navbar from './components/navbar';
import Footer from './components/footer';

// sections vvv
import Banner from './components/mainBanner/banner';
import Counter from './components/dateCounter/counter';
import Confirmation from './components/confirmationForm/confirmation';
import Giftlist from './components/giftlist/giftlist';
import Maps from './components/maps/maps';
import Playlist from './components/playlist/playlist';
import About from './components/aboutUs/about-us';
import DresscodeTips from './components/codeAndTips/dresscode-and-tips';
import Farewell from './components/farewell/farewell';

const App = () => {
  return (
    <ParallaxProvider>
      <Banner />
      <Counter />
      <Confirmation />
      <Giftlist />
      <Maps />
      <DresscodeTips />
      <About />
      <Playlist />
      <Farewell />
    </ParallaxProvider>
  );
}

export default App; 
