import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Confirmation = () => {
    const [state, setState] = useState({
        arriving: '',
        guest: '',
        email: '',
        message: ''
    });
    const [confirmation, setConfirmation] = useState(false)
    const handleChange = e => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const handleValidation = () => {
        const { arriving, guest } = state;
        if (arriving !== '' && guest !== '') return true;
        return false;
    }
    function sendEmail(e) {
        e.preventDefault();
        if (!!e.target) {
            emailjs.sendForm('service_1zzsqte', 'template_mqv6wqo', e.target, '1679v7bLVXSUhCGK5')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
            setState({
                arriving: false,
                guest: '',
                email: '',
                message: ''
            })
            setConfirmation(true)
            setTimeout(() => {
                setConfirmation(false)
            }, 5000);
        }
    }
    return (
        <section id="confirmation">
            <div className="container-fluid p-0 text-white text-center position-relative">
                <img src="/static/CivilPaulayInaki-152.jpg" className='confirmationImg' />
                <div className="container-fluid card-img-overlay bgContactForm p-0 m-0">
                    <form onSubmit={e => sendEmail(e)}>
                        <div className="row justify-content-center w-100 pt-xxl-5 g-0">
                            <div className="col-12 col-lg-6 bgContactForm px-2 px-lg-4">
                                <div className="spacer-5"></div>
                                <div className="row mb-3">
                                    <div className="col text-center">
                                        <h2 className="form-label title-gold font-raleway">
                                            ¡ESTÁS INVITADO!
                                            <br />
                                            ¿ASISTIRÁS?
                                        </h2>
                                        <p>
                                            RSVP hasta el 22 de Julio, por favor confirma tu asistencia.
                                        </p>
                                    </div>
                                </div>
                                <div className="mb-3 form-group justify-content-center">
                                    <div className="row">
                                        <div className="col text-start">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="arriving" id="arrivingCheckYES" value="SI" checked={state.arriving === 'SI'}
                                                    onChange={e => handleChange(e)} />
                                                <label className="form-check-label" htmlFor="arrivingCheckYES">
                                                    Sí, voy!
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-4">
                                        <div className="col text-start">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="arriving" id="arrivingCheckNO" value="NO" checked={state.arriving === 'NO'}
                                                    onChange={e => handleChange(e)} />
                                                <label className="form-check-label" htmlFor="arrivingCheckNO">
                                                    No, me voy a perder el mejor matrimonio del año.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className='my-5' />
                                <div className="row mb-3 justify-content-center">
                                    <div className="col-12 col-lg-10 form-group">
                                        <input
                                            type="text"
                                            id="guest"
                                            className="form-control bg-black border-2 rounded-0 text-center"
                                            name="guest"
                                            value={state.guest}
                                            onChange={e => handleChange(e)}
                                            placeholder="Nombre y Apellido" />
                                    </div>
                                </div>
                                <div className="row mb-3 justify-content-center">
                                    <div className="col-12 col-lg-10 form-group">
                                        <input
                                            type="email"
                                            id="email"
                                            className="form-control bg-black border-2 rounded-0 text-center"
                                            name="email"
                                            value={state.email}
                                            onChange={e => handleChange(e)}
                                            placeholder="Correo Electrónico (Opcional)" />
                                    </div>
                                </div>
                                <div className="row my-5 justify-content-center">
                                    <div className="col-12 col-lg-10 form-group">
                                        <label htmlFor="message" className="form-label">¿Hay algo que debamos saber?</label>
                                        <textarea
                                            className="form-control bg-black border-2 rounded-0 text-center"
                                            id="message"
                                            name="message"
                                            value={state.message}
                                            onChange={e => handleChange(e)}
                                            rows="5"
                                            placeholder="Necesidades especiales, alergias alimentarias, etc."></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <small>* Un formulario por persona.</small>
                                    </div>
                                </div>
                                <div className="row my-5 justify-content-center">
                                    <div className="col text-center">
                                        <button type="submit" className={"btn btn-black fw-bolder fs-5 " + (handleValidation() == false ? "disabled" : "")}>Enviar</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <small>Puedes enviarnos tu pase de movilidad a <a href="mailto:matri.paulaeinaki@gmail.com" className='title-gold no-deco' target="_blank" rel="noopener noreferrer">matri.paulaeinaki@gmail.com</a> o súbelo haciendo click <a href="https://drive.google.com/drive/folders/1s1KA-fXOSY44XjLYT2AYrTQavSAsOb1w?usp=sharing" className='title-gold' target="_blank" rel="noopener noreferrer">aquí</a></small>
                                    </div>
                                </div>
                                <div className="spacer-5"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {
                confirmation == true && (
                    <div className={"confirmationMsg container-fluid position-absolute top-0 start-0 d-flex justify-content-center align-items-center " + (confirmation == true ? "fade show" : "fade hide")}>
                        <div className="row modalConfirmation text-white p-4">
                            <div className="col-11">
                                <h5 className="modal-title" id="exampleModalLabel">Gracias por confirmar, tu respuesta ha sido enviada!</h5>
                            </div>
                            <div className="col-1 text-end">
                                <button type="button" className="btn-close" onClick={() => setConfirmation(false)}></button>
                            </div>
                        </div>
                    </div>
                )
            }
        </section>
    )
}

export default Confirmation;




{/* <section id="confirmation">
<div className="container-fluid p-0 text-white text-center position-relative">
    <img src="/static/CivilPaulayInaki-152.jpg" className='confirmationImg' />
    <div className="container-fluid card-img-overlay bgContactForm p-0 m-0">
        <div className="row justify-content-center bgContactForm">
            <div className="spacer-5"></div>
            <div className="col-12 col-lg-6">
                <form onSubmit={e => sendEmail(e)}>
                    <div className="row mb-3">
                        <div className="col text-center">
                            <h2 className="form-label title-gold font-raleway">¿NOS ACOMPAÑAS?</h2>
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. In omnis fugiat pariatur expedita suscipit eligendi maxime, minus tenetur deleniti consectetur iste porro laborum repellat cupiditate sequi minima quasi quis. Officiis.
                            </p>
                        </div>
                    </div>
                    <div className="mb-3 form-group justify-content-center">
                        <div className="row">
                            <div className="col text-start">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="arriving" id="arrivingCheckYES" value="SI" checked={state.arriving === 'SI'}
                                        onChange={e => handleChange(e)} />
                                    <label className="form-check-label" htmlFor="arrivingCheckYES">
                                        Sí, voy!
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-4">
                            <div className="col text-start">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="arriving" id="arrivingCheckNO" value="NO" checked={state.arriving === 'NO'}
                                        onChange={e => handleChange(e)} />
                                    <label className="form-check-label" htmlFor="arrivingCheckNO">
                                        Me encantaría, pero no
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='my-5' />
                    <div className="row mb-3 justify-content-center">
                        <div className="col-12 col-lg-10 form-group">
                            <input
                                type="text"
                                id="guest"
                                className="form-control bg-black border-2 rounded-0 text-center"
                                name="guest"
                                value={state.guest}
                                onChange={e => handleChange(e)}
                                placeholder="Nombre y Apellido" />
                        </div>
                    </div>
                    <div className="row mb-3 justify-content-center">
                        <div className="col-12 col-lg-10 form-group">
                            <input
                                type="email"
                                id="email"
                                className="form-control bg-black border-2 rounded-0 text-center"
                                name="email"
                                value={state.email}
                                onChange={e => handleChange(e)}
                                placeholder="Correo Electrónico (Opcional)" />
                        </div>
                    </div>
                    <div className="row my-5 justify-content-center">
                        <div className="col-12 col-lg-10 form-group">
                            <label htmlFor="message" className="form-label">¿Hay algo que debamos saber?</label>
                            <textarea
                                className="form-control bg-black border-2 rounded-0 text-center"
                                id="message"
                                name="message"
                                value={state.message}
                                onChange={e => handleChange(e)}
                                rows="5"
                                placeholder="Necesidades especiales, alergias alimentarias, etc."></textarea>
                        </div>
                    </div>
                    <div className="row mb-5 justify-content-center">
                        <div className="col">
                            <small className='text-center'>
                                Al confirmar mi asistencia yada yadda yadda... te vamos a buscar y te vamos a cobrar.
                            </small>
                        </div>
                    </div>
                    <div className="row mb-3 justify-content-center">
                        <div className="col text-center">
                            <button type="submit" className={"btn btn-black font-raleway fw-bolder fs-5 " + (handleValidation() == false ? "disabled" : "")}>Enviar</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <small>Puedes enviarnos tu pase de movilidad a matri.paulaeinaki@gmail.com o súbelo haciendo click <a href="" className='title-gold'>aquí</a></small>
                        </div>
                    </div>
                    <div className="spacer-5"></div>
                </form>
            </div>
        </div>
    </div>
</div>
{
    confirmation == true && (
        <div className={"confirmationMsg container-fluid position-absolute top-0 start-0 d-flex justify-content-center align-items-center " + (confirmation == true ? "fade show" : "fade hide")}>
            <div className="row modalConfirmation text-white p-4">
                <div className="col-11">
                    <h5 className="modal-title" id="exampleModalLabel">Gracias por confirmar, tu respuesta ha sido enviada!</h5>
                </div>
                <div className="col-1 text-end">
                    <button type="button" className="btn-close" onClick={() => setConfirmation(false)}></button>
                </div>
            </div>
        </div>
    )
}
</section> */}