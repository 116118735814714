import React from 'react';

const Maps = () => {
    return (
        <section id="maps">
            <div className="container-fluid text-center">
                <div className="spacer-15"></div>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6">
                        <h2 className="form-label font-raleway">¿CÓMO LLEGAR?</h2>
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col-12 col-lg-6">
                        <h5 className='fw-bolder'>CEREMONIA</h5>
                        <h6>PARROQUIA JESÚS NAZARENO</h6>
                        <p>Av. Manuel Montt 797, Providencia</p>
                        <iframe title="Church" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.5348693919755!2d-70.61894088427744!3d-33.43536890412553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf7ea61ab1d1%3A0x9964259a71c4d2b9!2sJes%C3%BAs%20Nazareno%20Parish!5e0!3m2!1sen!2scl!4v1645110256037!5m2!1sen!2scl" className="iframeTab"></iframe>
                    </div>
                </div>
                <div className="spacer-5"></div>
                <div className="row justify-content-center mt-5">
                    <div className="col-12 col-lg-6">
                        <h5 className='fw-bolder'>RECEPCIÓN</h5>
                        <h6>PALACIO SUBERCASEAUX</h6>
                        <p>
                            Agustinas 733, Santiago
                            <br />
                            <small className='text-muted'>Convenio con estacionamiento del Teatro Municipal ($3000 sin límite de tiempo)</small>
                        </p>
                        <iframe title="Palace" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.3432771712774!2d-70.64941008427732!3d-33.4403620043817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c5a18c7d6ddb%3A0x5fd8be03334c8b73!2sCasas%20Del%20Palacio%20De%20Subercaseaux!5e0!3m2!1sen!2scl!4v1645132036088!5m2!1sen!2scl" className="iframeTab"></iframe>
                    </div>
                </div>
                <div className="spacer-10"></div>
            </div>
        </section>
    )
}

export default Maps;