import React from "react";

const Farewell = () => {
    return (
        <section id="farewell">
            <div className="container-fluid p-0 text-white text-center position-relative">
                <img src="/static/CivilPaulayInaki-53.jpg" className='farewellImg' />
                <div className="container-fluid card-img-overlay d-flex flex-column justify-content-center">
                    <div className="row">
                        <div className="col">
                            <h1 className="display-1">¡NOS VEMOS!</h1>
                                <h2 className="fs-2">06 Agosto 2022</h2>
                                <h3 className='fw-300'>Santiago, Chile</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Farewell;