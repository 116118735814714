import React from 'react';

const DresscodeTips = () => {
    return (
        <section id="maps" className='bg-black text-white'>
            <div className="container">
                <div className="row justify-content-center">
                <div className="spacer-10"></div>
                    <div className="col-12 col-lg-6 text-center">
                        <h1>
                            PARA CONSIDERAR
                        </h1>
                        <p className='mt-4'>Código de vestimenta formal</p>
                        <ul className='list-goup mt-3'>
                            <li className='list-group-item py-3'>Se exigirá pase de movilidad para asistir al evento</li>
                            <li className='list-group-item py-3'>Existe un convenio con el estacionamiento del Teatro Municipal, con tarifa única de $3.000</li>
                            <li className='list-group-item py-3'>A nuestras invitadas les sugerimos llevar zapatos cómodos para la fiesta, ¡queremos que disfruten con nosotros!</li>
                            <li className='list-group-item py-3'>Recuerda ir bien preparado para el frío, aún estaremos en invierno</li>
                        </ul>
                    </div>
                </div>
                <div className="spacer-10"></div>
            </div>
        </section>
    )
}

export default DresscodeTips;